import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import { TextField, Button, Typography, Box, Select, MenuItem } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import S3 from 'react-s3';
import UserClasses from '../users/Users.module.css';
import AddProductClasses from '../products/Products.module.css';
import OrderClasses from '../orders/Orders.module.css';
import { Success, Error_Dark } from 'Util/Toast';
import CommonTutorClasses from '../commoncssfiles/CategoryTutor.module.css';

const EditProduct = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [base64, setBase64] = useState('');
    const [locationId, setLocationId] = useState(''); // State for location ID


    // Configuration for AWS S3
    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'product-images',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    // Function to handle file upload
    const filehandleChange = async (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp')) {
            const timestamp = Date.now();
            const fileExtension = file.type.split('/')[1];
            const newFile = new File([file], `${timestamp}.${fileExtension}`);

            try {
                const response = await S3.uploadFile(newFile, config);

                const fullUrl = response.location;
                const fileName = fullUrl.split('/').pop(); // Extract filename from URL

                setBase64(fullUrl);
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    imageUrl: fileName, // Set only the filename as imageUrl
                }));
            } catch (err) {
                console.error('Error uploading file:', err);
            }
        } else {
            console.error(`${file.name} is neither PNG, JPEG, nor WEBP`);
        }
    };

    // Fetch product details on component mount
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };
                const response = await Services.getProductById(productId, token);

                if (response.Status === 1) {
                    setProduct({ ...response.productDetails });
                    setLocationId(response.productDetails.locationId); // Set initial location ID
                    setBase64(response.productDetails?.url); // Set base64 to the full image URL
                } else {
                    console.error('Error fetching product:', response.Message);
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [productId]);

    // Handle input change for form fields
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Check if the name matches the nutritional value pattern
        const match = name.match(/^(key|value|unit)(\d+)$/);

        if (match) {
            // If the name matches, handle nutritional value change
            const [fieldType, index] = match.slice(1);

            setProduct((prevProduct) => {
                const updatedNutritionalValues = prevProduct.nutritionalValues.map((nutrition, idx) => {
                    if (idx === parseInt(index, 10)) {
                        return {
                            ...nutrition,
                            [fieldType]: value,
                        };
                    }
                    return nutrition;
                });

                return {
                    ...prevProduct,
                    nutritionalValues: updatedNutritionalValues,
                };
            });
        } else {
            // Else, handle other fields directly
            setProduct((prevProduct) => ({
                ...prevProduct,
                [name]: value,
            }));
        }
    };

    // Handle location change
    const handleLocationChange = (event) => {
        setLocationId(event.target.value);
    };

    // Save product changes
    const handleSave = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('Token')}` };
            const payload = {
                categoryId: product.categoryId,
                productName: product.productName,
                imageUrl: product.imageUrl,
                description: product.description,
                status: product.status,
                nutritionalValues: product.nutritionalValues,
                locationId: locationId, // Include locationId in the payload

            };

            const response = await Services.editProduct(productId, payload, token);

            if (response.Status === 1) {
                Success('Product updated successfully');
                navigate('/products');
            } else {
                Error_Dark(response.Message);
            }
        } catch (error) {
            console.error('Error updating product:', error);
            Error_Dark('Failed to update product. Please try again.');
        }
    };

    // Show loading state while fetching product
    if (loading || !product) {
        return <div>Loading...</div>;
    }

    // File upload design structure
    const fileuploadDesign = (
        <div className={CommonTutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={CommonTutorClasses['upload-image']}>
                {base64 && <img src={base64} alt="Selected" className={CommonTutorClasses['uploaded-coupon-image']} />}
                {!base64 && (
                    <div className={CommonTutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
                        <UploadIcon className={CommonTutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    // Render component UI
    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Product</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <div className={UserClasses['users-main']}>
                        <h2 className={OrderClasses['header-form']}>Edit Product</h2>
                        <form className={AddProductClasses['product-form']} data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom">
                            <div className={AddProductClasses['main-prod-add-new']}>
                                <Box style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="productName">Product Name</label>
                                    <input
                                        type="text"
                                        className={CommonTutorClasses['text-input']}
                                        value={product.productName || ''}
                                        onChange={handleInputChange}
                                        name="productName"
                                        required
                                    />
                                </Box>
                                <Box style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="categoryName">Category Name</label>
                                    <input
                                        type="text"
                                        className={CommonTutorClasses['text-input']}
                                        value={product.categoryName || ''}
                                        onChange={handleInputChange}
                                        name="categoryName"
                                        required
                                    />
                                </Box>
                                <Box style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="location">Location</label>
                                    <Select
                                        value={locationId}
                                        onChange={handleLocationChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Select Location' }}
                                    >
                                        <MenuItem value={1}>Store</MenuItem>
                                        <MenuItem value={2}>Inventory</MenuItem>
                                        <MenuItem value={3}>Both Store & Inventory</MenuItem>
                                    </Select>
                                </Box>
                                <Box style={{ width: '400px' }} className={CommonTutorClasses['form-control']}>
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        className={CommonTutorClasses['text-input']}
                                        value={product.description || ''}
                                        onChange={handleInputChange}
                                        name="description"
                                        rows="4"
                                        required
                                    />
                                </Box>

                            </div>
                            <Box style={{ marginTop: '10px' }} className={CommonTutorClasses['file-upload-wrapper']}>
                                <header>
                                    <h3>
                                        Upload Product Image <span className="important">*</span>
                                    </h3>
                                </header>
                                <div className={CommonTutorClasses["upload-image"]} required>
                                    {fileuploadDesign}
                                </div>
                            </Box>

                            {/* Nutritional values */}
                            <Typography variant="subtitle1" className={AddProductClasses["image-label-two"]}>
                                Nutritional Values:
                            </Typography>
                            {product.nutritionalValues && product.nutritionalValues.length > 0 && (
                                product.nutritionalValues.map((nutrition, index) => (
                                    <div className={CommonTutorClasses['form-control']}>
                                        <Box key={index} className={AddProductClasses['main-prod-add-new']}>
                                            <input
                                                type="text"
                                                className={CommonTutorClasses['text-input']}
                                                value={nutrition.key || ''}
                                                onChange={handleInputChange}
                                                name={`key${index}`}
                                                required
                                                style={{ padding: '10px', width: '27%', marginTop: '10px' }}
                                            />
                                            <input
                                                type="text"
                                                className={CommonTutorClasses['text-input']}
                                                value={nutrition.value || ''}
                                                onChange={handleInputChange}
                                                name={`value${index}`}
                                                required
                                                style={{ padding: '10px', width: '27%', marginTop: '10px' }}
                                            />
                                            <Select
                                                value={nutrition.unit || ''}
                                                onChange={(e) => handleInputChange({ target: { name: `unit${index}`, value: e.target.value } })}
                                                displayEmpty
                                                style={{ width: '27%', height: '49px', marginTop: '10px' }}
                                            >
                                                <MenuItem value="">Select Unit</MenuItem>
                                                <MenuItem value="g">Grams (g)</MenuItem>
                                                <MenuItem value="mg">Milligrams (mg)</MenuItem>
                                                <MenuItem value="µg">Micrograms (µg)</MenuItem>
                                                <MenuItem value="mcg">Micrograms (mcg)</MenuItem>
                                                <MenuItem value="IU">International Units (IU)</MenuItem>
                                                <MenuItem value="RE">Retinol Equivalents (RE)</MenuItem>
                                                <MenuItem value="DFE">Dietary Folate Equivalents (DFE)</MenuItem>
                                                <MenuItem value="kcal">Kilocalories (kcal)</MenuItem>
                                                <MenuItem value="kJ">Kilojoules (kJ)</MenuItem>
                                                <MenuItem value="mEq">Milliequivalents (mEq)</MenuItem>
                                                <MenuItem value="ppm">Parts Per Million (ppm)</MenuItem>
                                                <MenuItem value="mL">Milliliters (mL)</MenuItem>
                                                <MenuItem value="µmol">Micromoles (µmol)</MenuItem>
                                                <MenuItem value="eq">Equivalents (eq)</MenuItem>
                                            </Select>
                                        </Box>
                                    </div>
                                ))
                            )}

                            {/* Save and Cancel buttons */}
                            <div className={AddProductClasses['button-container']}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => navigate('/products')}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditProduct;
