import React, { useState, useEffect } from 'react';
import UserClasses from './Users.module.css';
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { Services } from 'Services';
import LoadingSpinnerComponent from 'react-spinners-components';
import nodataimg from '../../../Assets/nodatafoundimg.jpg';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, TextField } from '@mui/material';
import { FaRegEdit, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { Popover } from 'react-tiny-popover';
import CategoryClasses from '../categories/AddCategory.module.css';
import { MdFamilyRestroom, MdDeliveryDining } from "react-icons/md";
import { FaBriefcaseMedical } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import { RiShoppingBasketFill } from "react-icons/ri";
import deliveryslotimg from '../../../Assets/delivery-man-2.png'
import medicalimg from '../../../Assets/medicalcondition4.jpg.png'
import familyimg from '../../../Assets/family1.jpg.png'
import walletimg from '../../../Assets/walletimg1.jpg.png'
import basketimg from '../../../Assets/basket-img1.png'
import Guestsimg from '../../../Assets/guests1.jpg'
import vatationimg from '../../../Assets/vacationimg (1).png'
import { Success } from 'Util/Toast';


const UserDetails = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [basketDetails, setBasketDetails] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [guests, setGuests] = useState([]);
    const [vacations, setVacations] = useState([]);
    const [openModal, setOpenModal] = useState(false); // To manage modal visibility
    const [totalAdultCount, setTotalAdultCount] = useState(0); // To manage adult count input
    const [totalChildCount, setTotalChildCount] = useState(0); // To manage child count input
    const [modalType, setModalType] = useState('details'); // To manage modal type (if you need to use it)
    const [openMedicalModal, setOpenMedicalModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    // State for medical condition and details form fields
    const [medicalCondition, setMedicalCondition] = useState(0);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [openVacationModal, setOpenVacationModal] = useState(false);
    const [vacationAdults, setVacationAdults] = useState('');
    const [vacationChildren, setVacationChildren] = useState('');
    const [departDate, setDepartDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [vacationId, setVacationId] = useState(null);




    const token = { Authorization: `token ${localStorage.getItem('Token')}` };

    // Fetch user details function, move it outside useEffect for accessibility
    const fetchUserDetails = async () => {
        try {
            const response = await Services.getUsersDetails(userId, token);
            setUserDetails(response.userDetails[0]);
            setBasketDetails(response.basketDetails[0]);
            setGuests(response.guests);  // Set the guests data
            setVacations(response.vacations);  // Set the vacations data
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [userId]);
    const formatDeliveryTime = (timeString) => {
        if (!timeString) return 'N/A';

        const [hours, minutes] = timeString.split(':');
        const formattedTime = `${parseInt(hours, 10) % 12 || 12}:${minutes} ${parseInt(hours, 10) >= 12 ? 'PM' : 'AM'}`;

        return formattedTime;
    };

    const handleBasketNameClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleEditBasket = () => {
        navigate(`/edit-basket/${userDetails.basketId}`);
    };

    const handleEditFamily = () => {
        // Implement the logic to handle editing family details, such as opening a modal or navigating to another page.
        console.log("Edit Family Details");
    };

    // Handle opening medical condition modal
    const handleEditMedicalCondition = () => {
        setOpenMedicalModal(true);
        setMedicalCondition(userDetails.medicalCondition); // Prefill the modal with the current medical condition
    };

    // Handle opening details modal
    const handleEditDetails = () => {
        setOpenDetailsModal(true);
        setTotalAdultCount(userDetails.totalAdultCount); // Prefill the modal with the current details
        setTotalChildCount(userDetails.totalChildCount); // Prefill the modal with the current details
    };


    // Handle closing modals
    const handleCloseModal = () => {
        setOpenModal(false); // Close the modal
        setOpenMedicalModal(false);
        setOpenDetailsModal(false);
    };

    const handleSaveMedicalCondition = async () => {
        const body = {
            type: "details",
            medicalCondition,
        };

        try {
            const response = await Services.updateUserDetails(userId, body, token);
            if (response.Status === 1) {
                console.log('Medical condition updated successfully:', response);
                Success(response.Message)
                fetchUserDetails();
            } else {
                console.log('Failed to update medical condition');
            }
        } catch (error) {
            console.error('Error updating medical condition:', error);
        }
        setOpenMedicalModal(false);
    };


    const handleSaveFamilyDetails = async () => {
        // Prepare the body for the API call
        const body = {
            type: "details",
            totalAdultCount,
            totalChildCount,
        };

        try {
            // Call the API to update user details
            const response = await Services.updateUserDetails(userId, body, token);

            if (response.Status === 1) {
                console.log('Updated successfully:', response);
                Success(response.Message)
                fetchUserDetails();
                // Optionally, show a success message or update the UI state
            } else {
                console.log('Failed to update details');
            }
        } catch (error) {
            console.error('Error updating details:', error);
        }

        setOpenModal(false); // Close the modal after saving
    };

    const handleEditFamilyDetails = () => {
        setOpenModal(true);
        setTotalAdultCount(userDetails.totalAdultCount);
        setTotalChildCount(userDetails.totalChildCount);
    };

    // Open the modal with prefilled data
    const handleEditGuest = (guest) => {
        setSelectedGuest(guest);
        setOpenEditModal(true);
    };

    // Close the modal
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setSelectedGuest(null);
    };

    const handleSaveGuestDetails = async () => {
        if (!selectedGuest) return;

        // Prepare the body for the API call
        const body = {
            type: "guests",
            guestsId: selectedGuest.guestsId,
            guestAdults: selectedGuest.editedGuestAdults,
            guestChildren: selectedGuest.editedGuestChildren,
            arrivalDate: selectedGuest.editedArrivalDate,
            leaveDate: selectedGuest.editedLeaveDate,
        };

        try {
            // Call the API to update guest details
            const response = await Services.updateUserDetails(userId, body, token);

            if (response.Status === 1) {
                console.log('Guest details updated successfully:', response);
                Success(response.Message); // Optionally show a success notification
                fetchUserDetails();
                // Update the guests list in the UI (if necessary)
                // E.g., re-fetch the guests data or update local state
            } else {
                console.log('Failed to update guest details');
            }
        } catch (error) {
            console.error('Error updating guest details:', error);
        }

        setOpenEditModal(false); // Close the modal after saving
    };


    // Update fields in the selected guest
    const handleInputChange = (field, value) => {
        setSelectedGuest({ ...selectedGuest, [field]: value });
    };

    const handleEditVacation = (vacation) => {
        setVacationAdults(vacation.editedVacationAdults);
        setVacationId(vacation.vacationId); // Set the vacationId for the selected vacation
        setVacationChildren(vacation.editedVacationChildren);
        setDepartDate(new Date(vacation.editedDepartDate).toISOString().split("T")[0]);
        setReturnDate(new Date(vacation.editedReturnDate).toISOString().split("T")[0]);
        setOpenVacationModal(true);
    };

    const handleCloseVacationModal = () => {
        setOpenVacationModal(false);
    };

    const handleSaveVacationDetails = async () => {
        const body = {
            type: "vacations",
            vacationId,
            vacationAdults,
            vacationChildren,
            departDate,
            returnDate,
        };

        try {
            const response = await Services.updateUserDetails(userId, body, token);

            if (response.Status === 1) {
                console.log('Vacation details updated successfully:', response);
                Success(response.Message);
                fetchUserDetails();
                // Optionally update the vacation list in UI
            } else {
                console.log('Failed to update vacation details');
            }
        } catch (error) {
            console.error('Error updating vacation details:', error);
        }

        setOpenVacationModal(false); // Close the modal
    };

    return (
        <Layout Active={'Account Settings'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Details</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={UserClasses['add-users-btn']}>
                        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                    </div>
                    <h2 className={UserClasses['user-details']}>User Details</h2>
                    {loading ? (
                        <div className={UserClasses['spinner-container']}>
                            <LoadingSpinnerComponent type={'Spinner'} color={'green'} size={'100px'} />
                        </div>
                    ) : (
                        <div className={UserClasses['inside-userdetails']}>
                            {userDetails ? (
                                <div>
                                    <div className={UserClasses['user-fam-card']} >
                                        <div className={UserClasses['user-card']} data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="500">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h3>Family</h3>
                                                <button className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`} onClick={handleEditFamilyDetails}>
                                                    <FaRegEdit style={{ color: 'brown' }} />
                                                </button>
                                            </div>
                                            <div className={UserClasses['card-cont-img']}>
                                                <div className={UserClasses['card-inside-content']}>
                                                    <p>Adults: {userDetails.totalAdultCount}</p>
                                                    <p>Children: {userDetails.totalChildCount}</p>
                                                </div>
                                                <div className={UserClasses['card-inside-image']}>
                                                    <img style={{ height: '150px', width: '170px', marginLeft: '20px' }} src={familyimg} alt="Page Not Found" className={UserClasses['no-data-img']} />
                                                </div>
                                            </div>
                                            {/* Edit button for Family */}

                                        </div>
                                        <Dialog
                                            open={openModal}
                                            onClose={handleCloseModal}
                                            data-aos="fade-up"
                                            sx={{
                                                '& .MuiDialog-paper': {
                                                    borderRadius: '10px',
                                                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                                                    padding: '20px',
                                                    width: '500px',
                                                    margin: '0 auto',
                                                },
                                                '& .MuiDialogTitle-root': {
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    color: '#333',
                                                    marginBottom: '20px',
                                                },
                                                '& .MuiDialogContent-root': {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '15px',
                                                    fontSize: '1rem',
                                                    color: '#555',
                                                },
                                                '& .MuiDialogActions-root': {
                                                    justifyContent: 'center',
                                                    padding: '15px',
                                                },
                                            }}
                                        >
                                            <DialogTitle style={{ color: '#44E31E' }}>Edit Family Details</DialogTitle>
                                            <DialogContent>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <div>
                                                        <label
                                                            htmlFor="totalAdultCount"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                marginRight: '10px',
                                                            }}
                                                        >
                                                            Total Adults:
                                                        </label>
                                                        <input
                                                            type="number"
                                                            id="totalAdultCount"
                                                            value={totalAdultCount}
                                                            onChange={(e) => setTotalAdultCount(e.target.value)}
                                                            style={{
                                                                padding: '8px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                width: '100%',
                                                                // maxWidth: '200px',
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label
                                                            htmlFor="totalChildCount"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                marginRight: '10px',
                                                            }}
                                                        >
                                                            Total Children:
                                                        </label>
                                                        <input
                                                            type="number"
                                                            id="totalChildCount"
                                                            value={totalChildCount}
                                                            onChange={(e) => setTotalChildCount(e.target.value)}
                                                            style={{
                                                                padding: '8px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                width: '100%',
                                                                // maxWidth: '200px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={handleSaveFamilyDetails}
                                                    style={{
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    onClick={handleCloseModal}
                                                    style={{
                                                        backgroundColor: 'gray',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <div className={UserClasses['user-card']} data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <h3>Medical Condition</h3>
                                                <button className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`} onClick={handleEditMedicalCondition}>
                                                    <FaRegEdit style={{ color: 'brown' }} />
                                                </button>
                                            </div>
                                            <div className={UserClasses['card-cont-img']}>
                                                <div className={UserClasses['card-inside-content']}>
                                                    <p
                                                        title={userDetails.medicalCondition} // Display full content as tooltip
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '150px', // Adjust the width based on your requirement
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {userDetails.medicalCondition}
                                                    </p>
                                                </div>

                                                <div className={UserClasses['card-inside-image']}>
                                                    <img style={{ height: '150px', marginLeft: '20px' }} src={medicalimg} alt="Page Not Found" className={UserClasses['no-data-img']} />
                                                </div>
                                            </div>
                                            {/* Edit button for Medical Condition */}

                                        </div>
                                        {/* Medical Condition Modal */}
                                        <Dialog
                                            open={openMedicalModal}
                                            onClose={handleCloseModal}
                                            data-aos="fade-up"
                                            sx={{
                                                '& .MuiDialog-paper': {
                                                    borderRadius: '10px',
                                                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                                                    padding: '20px',
                                                    width: '500px',
                                                    margin: '0 auto',
                                                },
                                                '& .MuiDialogTitle-root': {
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    color: '#333',
                                                    marginBottom: '20px',
                                                },
                                                '& .MuiDialogContent-root': {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '15px',
                                                    fontSize: '1rem',
                                                    color: '#555',
                                                },
                                                '& .MuiDialogActions-root': {
                                                    justifyContent: 'center',
                                                    padding: '15px',
                                                },
                                            }}
                                        >
                                            <DialogTitle style={{ color: '#44E31E' }}>Edit Medical Condition</DialogTitle>
                                            <DialogContent>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                    <div>
                                                        <label
                                                            htmlFor="medicalCondition"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                marginBottom: '8px',
                                                                display: 'block',
                                                            }}
                                                        >
                                                            Medical Condition:
                                                        </label>
                                                        <textarea
                                                            id="medicalCondition"
                                                            value={medicalCondition}
                                                            onChange={(e) => setMedicalCondition(e.target.value)}
                                                            rows="4"
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '5px',
                                                                fontSize: '1rem',
                                                                fontFamily: 'Arial, sans-serif',
                                                                color: '#333',
                                                                resize: 'none',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={handleSaveMedicalCondition}
                                                    style={{
                                                        backgroundColor: 'black',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    onClick={handleCloseModal}
                                                    style={{
                                                        backgroundColor: 'gray',
                                                        color: 'white',
                                                        padding: '10px 20px',
                                                        borderRadius: '5px',
                                                        margin: '5px',
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <div className={UserClasses['user-card']} data-aos="zoom-in"
                                            data-aos-easing="ease-out-cubic"
                                            data-aos-duration="1500">
                                            <h3>Delivery Slot</h3>
                                            <div className={UserClasses['card-cont-img']}>
                                                <div className={UserClasses['card-inside-content']}>
                                                    <p>{userDetails.deliverySlot}  {formatDeliveryTime(userDetails.deliveryTime)}</p>
                                                </div>
                                                <div className={UserClasses['card-inside-image']}>
                                                    <img style={{ height: '150px', marginLeft: '20px' }}
                                                        src={deliveryslotimg}
                                                        alt="Page Not Found"
                                                        className={UserClasses['no-data-img']}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={UserClasses['user-card']} data-aos="zoom-in"
                                            data-aos-easing="ease-out-cubic"
                                            data-aos-duration="2000">
                                            <h3>Wallet Status</h3>
                                            <div className={UserClasses['card-cont-img']}>
                                                <div className={UserClasses['card-inside-content']}>
                                                    <p>{userDetails.walletAmount > 0 ? 'Regular' : 'Running Low'}</p>
                                                </div>
                                                <div className={UserClasses['card-inside-image']}>
                                                    <img style={{ height: '150px', marginLeft: '20px' }}
                                                        src={walletimg}
                                                        alt="Page Not Found"
                                                        className={UserClasses['no-data-img']}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={UserClasses['user-card']} data-aos="zoom-in"
                                            data-aos-easing="ease-out-cubic"
                                            data-aos-duration="2500">
                                            <h3>Basket Status</h3>
                                            <div className={UserClasses['card-cont-img']}>
                                                <div className={UserClasses['card-inside-content']}>
                                                    <p>{basketDetails?.status === 1 ? 'Assigned' : 'UnAssigned'}</p>
                                                </div>
                                                <div className={UserClasses['card-inside-image']}>
                                                    {basketDetails?.status === 1 ? (
                                                        <p><FaCheckCircle style={{ color: 'green' }} /></p>
                                                    ) : (
                                                        <p><FaExclamationTriangle style={{ color: 'orange' }} /></p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {basketDetails ? (
                                            <div>
                                                <div className={UserClasses['user-card']} data-aos="zoom-in"
                                                    data-aos-easing="ease-out-cubic"
                                                    data-aos-duration="3000">
                                                    <div className={UserClasses['basket-card-edit-nav']}>
                                                        <h3>Basket Name</h3>
                                                        <button onClick={handleEditBasket} className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}>
                                                            <FaRegEdit style={{ color: 'black' }} className={UserClasses['fa-edit-bask']} />
                                                        </button>
                                                    </div>
                                                    <div className={UserClasses['card-cont-img']}>
                                                        <div>
                                                            <div className={UserClasses['card-inside-content']}>
                                                                <Popover
                                                                    isOpen={isPopoverOpen}
                                                                    position={['top', 'right', 'left', 'bottom']}
                                                                    padding={10}
                                                                    onClickOutside={() => setIsPopoverOpen(false)}
                                                                    content={<div className={UserClasses['popover-content']}>{basketDetails.basketName}</div>}
                                                                >
                                                                    <Button
                                                                        className={UserClasses['basket-card-name']}
                                                                        onMouseEnter={() => setIsPopoverOpen(true)}
                                                                        onMouseLeave={() => setIsPopoverOpen(false)}
                                                                        onClick={handleBasketNameClick}
                                                                    >
                                                                        {basketDetails.basketName}
                                                                    </Button>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={UserClasses['card-inside-image']}>
                                                                <img style={{ height: '150px', marginLeft: '20px' }}
                                                                    src={basketimg}
                                                                    alt="Page Not Found"
                                                                    className={UserClasses['no-data-img']}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Dialog open={openDialog} onClose={handleCloseDialog} data-aos="fade-up"
                                                    sx={{
                                                        '& .MuiDialog-paper': {
                                                            width: '400px',
                                                        },
                                                        '& .MuiDialogTitle-root': {
                                                            backgroundColor: 'black',
                                                            padding: '10px 20px',
                                                            textAlign: 'center',
                                                            color: 'white',
                                                        },
                                                        '& .MuiDialogContent-root': {
                                                            padding: '20px',
                                                        },
                                                        '& .MuiDialogActions-root': {
                                                            padding: '10px 20px',
                                                            justifyContent: 'flex-end',
                                                        },
                                                    }}>
                                                    <DialogTitle className={UserClasses['basket-card-popup-name']}>{basketDetails.basketName}</DialogTitle>
                                                    <DialogContent>
                                                        <List>
                                                            {basketDetails.products.map((product, index) => (
                                                                <ListItem key={index}>
                                                                    <img src={product.url} alt={product.name} className={UserClasses['basket-card']} style={{ width: '60px', height: '60px', marginRight: '10px', borderRadius: '50%', backgroundColor: 'gray' }} />
                                                                    <p className={UserClasses['para-basket-card']}>{product.name}</p>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button style={{ backgroundColor: 'black', color: 'white' }} onClick={handleCloseDialog}>Close</Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </div>
                                        ) : ''}
                                    </div>
                                    <div>
                                        {/* Guests Section */}
                                        {guests && guests.length > 0 && (
                                            // <div className={UserClasses['user-fam-card']}>
                                            <div style={{ backgroundColor: '#F3F7FB', marginTop: '20px', borderRadius: '4px', border: '1px solid gray' }}>
                                                <h3 style={{backgroundColor: 'black', padding: '10px', color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Guests Information</h3>
                                                <table className={UserClasses['table']}>
                                                    <thead>
                                                        <tr>
                                                            <th>Adults</th>
                                                            <th>Children</th>
                                                            <th>Arrival</th>
                                                            <th>Leave</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {guests.map((guest, index) => (
                                                            <tr key={index}>
                                                                <td>{guest.editedGuestAdults}</td>
                                                                <td>{guest.editedGuestChildren}</td>
                                                                <td>{new Date(guest.editedArrivalDate).toLocaleDateString()}</td>
                                                                <td>{new Date(guest.editedLeaveDate).toLocaleDateString()}</td>
                                                                <td>
                                                                    <button
                                                                        onClick={() => handleEditGuest(guest)}
                                                                        className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                                                    >
                                                                        <FaRegEdit style={{ color: 'black', marginTop: '15px' }} className={UserClasses['fa-edit-bask']} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            // </div>
                                        )}

                                        <Dialog open={openEditModal} onClose={handleCloseEditModal}>
                                            <div style={{ border: '1px solid green', width: '500px' }}>
                                                <DialogTitle style={{ color: '#44E31E' }}>Edit Guest Details</DialogTitle>
                                                <DialogContent style={{ marginRight: '5px' }}>
                                                    {selectedGuest && (
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '10px' }}>
                                                            <TextField
                                                                label="Adults"
                                                                type="number"
                                                                value={selectedGuest.editedGuestAdults}
                                                                onChange={(e) => handleInputChange('editedGuestAdults', e.target.value)}
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                label="Children"
                                                                type="number"
                                                                value={selectedGuest.editedGuestChildren}
                                                                onChange={(e) => handleInputChange('editedGuestChildren', e.target.value)}
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                label="Arrival Date"
                                                                type="date"
                                                                value={new Date(selectedGuest.editedArrivalDate).toISOString().split('T')[0]}
                                                                onChange={(e) => handleInputChange('editedArrivalDate', e.target.value)}
                                                                InputLabelProps={{ shrink: true }}
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                label="Leave Date"
                                                                type="date"
                                                                value={new Date(selectedGuest.editedLeaveDate).toISOString().split('T')[0]}
                                                                onChange={(e) => handleInputChange('editedLeaveDate', e.target.value)}
                                                                InputLabelProps={{ shrink: true }}
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleSaveGuestDetails} style={{ backgroundColor: 'black', color: 'white' }}>
                                                        Save
                                                    </Button>
                                                    <Button onClick={handleCloseEditModal} style={{ backgroundColor: 'gray', color: 'white' }}>
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </div>
                                        </Dialog>


                                        {/* Vacations Section */}
                                        {vacations && vacations.length > 0 && (
                                            <div style={{ backgroundColor: '#F3F7FB', marginTop: '20px', borderRadius: '4px', border: '1px solid gray' }}>
                                                <h3 style={{backgroundColor: 'black', padding: '10px', color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Vacation Information</h3>
                                                <table className={UserClasses['table']}>
                                                    <thead>
                                                        <tr>
                                                            <th>Adults</th>
                                                            <th>Children</th>
                                                            <th>Depart</th>
                                                            <th>Return</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vacations.map((vacation, index) => (
                                                            <tr key={index}>
                                                                <td>{vacation.editedVacationAdults}</td>
                                                                <td>{vacation.editedVacationChildren}</td>
                                                                <td>{new Date(vacation.editedDepartDate).toLocaleDateString()}</td>
                                                                <td>{new Date(vacation.editedReturnDate).toLocaleDateString()}</td>
                                                                <td>
                                                                    <button
                                                                        onClick={() => handleEditVacation(vacation)}
                                                                        className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                                                    >
                                                                        <FaRegEdit style={{ color: 'black', marginTop: '15px' }} className={UserClasses['fa-edit-bask']} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        <Dialog open={openVacationModal} onClose={handleCloseVacationModal}>
                                            <div style={{ border: '1px solid green', width: '500px' }}>
                                                <DialogTitle style={{ color: '#44E31E' }}>Edit Vacation Information</DialogTitle>
                                                <DialogContent style={{ marginRight: '20px' }}>
                                                    <div style={{ marginBottom: '15px' }}>
                                                        <label htmlFor="vacationAdults">Adults: </label>
                                                        <input
                                                            type="number"
                                                            id="vacationAdults"
                                                            value={vacationAdults}
                                                            onChange={(e) => setVacationAdults(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                marginTop: '5px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ marginBottom: '15px' }}>
                                                        <label htmlFor="vacationChildren">Children: </label>
                                                        <input
                                                            type="number"
                                                            id="vacationChildren"
                                                            value={vacationChildren}
                                                            onChange={(e) => setVacationChildren(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                marginTop: '5px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ marginBottom: '15px' }}>
                                                        <label htmlFor="departDate">Depart Date: </label>
                                                        <input
                                                            type="date"
                                                            id="departDate"
                                                            value={departDate}
                                                            onChange={(e) => setDepartDate(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                marginTop: '5px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ marginBottom: '15px' }}>
                                                        <label htmlFor="returnDate">Return Date: </label>
                                                        <input
                                                            type="date"
                                                            id="returnDate"
                                                            value={returnDate}
                                                            onChange={(e) => setReturnDate(e.target.value)}
                                                            style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                marginTop: '5px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #ccc',
                                                            }}
                                                        />
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button
                                                        onClick={handleSaveVacationDetails}
                                                        style={{ backgroundColor: 'black', color: 'white' }}
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        onClick={handleCloseVacationModal}
                                                        style={{ backgroundColor: 'gray', color: 'white' }}
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </div>
                                        </Dialog>

                                    </div>
                                </div>
                            ) : (
                                <img
                                    src={nodataimg}
                                    alt="Page Not Found"
                                    className={UserClasses['no-data-img']}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default UserDetails;
