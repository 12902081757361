import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { CiSquarePlus } from 'react-icons/ci';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import ProductClasses from '../products/Products.module.css';
import foodLogo from '../../../Assets/foodbag2.jpg'
import { Button } from '@mui/material';
import { Success } from 'Util/Toast';
import { FaRegEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import CategoryClasses from '../categories/AddCategory.module.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


const ProductDetails = () => {
    const [productVariants, setProductVariants] = useState([]);
    const [addingVariant, setAddingVariant] = useState(false);
    const [newPrice, setNewPrice] = useState('');
    const [newStock, setNewStock] = useState('');
    const [metricType, setMetricType] = useState('');
    const [metricValue, setMetricValue] = useState('');
    const [nutritionalValues, setNutritionalValues] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [url, setUrl] = useState('');
    const metricValueOptions = ['ml', 'ltr', 'gm', 'kg', 'units'];
    const { productId } = useParams();
    const [error, setError] = useState(null);
    const [editingVariant, setEditingVariant] = useState(null);
    const [editedPrice, setEditedPrice] = useState('');
    const [editedStock, setEditedStock] = useState('');
    const [editedMetricType, setEditedMetricType] = useState('');
    const [editedMetricValue, setEditedMetricValue] = useState('');
    const [editError, setEditError] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State variable to track edit modal open/close
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate('');

    useEffect(() => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };
        Services.getProductVariants(productId, token)
            .then((response) => {
                console.log('Product Variants:', response);
                setProductVariants(response.productVariants);
                setNutritionalValues(response.productDetails.nutritionalValues);
                setImageUrl(response.productDetails.imageUrl);
                setUrl(response.productDetails.url)
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching product variants:', error);
                setLoading(false)
            });
    }, [productId]);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const handleAddVariantClick = () => {
        setAddingVariant(true);
    };

    const handleConfirmAddVariant = async () => {

        console.log(metricValue === 0)

        if (!metricType || !metricValue || !newPrice || parseInt(metricValue) === 0 || parseInt(newPrice) === 0) {
            setError('Please enter all the values');
            return;
        }

        const payload = {
            productId: parseInt(productId, 10),
            metricValue: parseInt(metricValue, 10),
            metricType,
            price: parseFloat(newPrice),
            stock: parseInt(newStock),
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.addProductVariant(payload, token);
            if (response.Status === 1) {
                console.log('Variant added successfully:', response);
                const updatedVariants = await Services.getProductVariants(productId, token);
                setProductVariants(updatedVariants.productVariants);
                Success(response.Message)
            } else {
                setError(response.Message)
                console.error('Error adding variant:', response.Message);
            }
        } catch (error) {
            console.error('Error adding variant:', error.message);
        } finally {
            setAddingVariant(false);
            setNewPrice('');
            setNewStock('');
            setMetricType('');
            setMetricValue('');
            setError(null);
        }
    };

    const selectedProductName = localStorage.getItem('selectedProductName');

    const handleCancelAddVariant = () => {
        setAddingVariant(false);
        setNewPrice('');
        setNewStock('');
        setMetricType('');
        setMetricValue('');
    };

    const openEditModal = (variant) => {
        setEditingVariant(variant);
        setEditedPrice(variant.price.toString());
        setEditedStock(variant.stock);
        setEditedMetricType(variant.metricType);
        setEditedMetricValue(variant.metricValue.toString());
        setIsEditModalOpen(true); // Open the edit modal
    };

    const handleEditVariant = async () => {
        // Validate input fields
        if (!editedMetricType || !editedMetricValue || !editedStock || !editedPrice || parseInt(editedMetricValue) === 0 || parseInt(editedPrice) === 0) {
            setEditError('Please enter all the values');
            return;
        }

        const payload = {
            metricValue: parseInt(editedMetricValue, 10),
            metricType: editedMetricType,
            price: parseFloat(editedPrice),
            stock: parseInt(editedStock),
        };

        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.editProductVariant(editingVariant.variantId, payload, token);
            if (response.Status === 1) {
                console.log('Variant edited successfully:', response);
                // Refresh product variants after editing
                const updatedVariants = await Services.getProductVariants(productId, token);
                setProductVariants(updatedVariants.productVariants);
                Success(response.Message);
                setIsEditModalOpen(false)
                // Close the edit modal
                setEditingVariant(null);
            } else {
                console.error('Error editing variant:', response.Message);
            }
        } catch (error) {
            console.error('Error editing variant:', error.message);
        } finally {
            setEditError(null);
        }
    };

    // Function to close the edit modal
    const closeEditModal = () => {
        setEditingVariant(null);
        setIsEditModalOpen(false); // Close the edit modal
    };

    const handleDeleteVariant = async (variantId) => {
        const token = { Authorization: `token ${localStorage.getItem('Token')}` };

        try {
            const response = await Services.deleteProductVariant(variantId, token);
            if (response.Status === 1) {
                console.log('Variant deleted successfully:', response);
                // Refresh product variants after deletion
                const updatedVariants = await Services.getProductVariants(productId, token);
                setProductVariants(updatedVariants.productVariants);
                Success(response.Message);
            } else {
                console.error('Error deleting variant:', response.Message);
            }
        } catch (error) {
            console.error('Error deleting variant:', error.message);
        }
    };


    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products</title>
            </Helmet>
            <div className={UserClasses['main-first-container']}>
                <div className={UserClasses['main-data-tab']}>
                    <div className={ProductClasses['main-add-btn']}>
                        <Button data-aos="zoom-in" variant="contained" color="primary" onClick={() => navigate(-1)}>
                            BACK
                        </Button>
                        <Button data-aos="zoom-in" variant="contained" color="primary" onClick={handleAddVariantClick}>
                            Add Variant <CiSquarePlus className={ProductClasses['CiSquarePlus']} />
                        </Button>
                    </div>
                    <div className={UserClasses['main-data-tab']}>
                        <div className={UserClasses['users-main']}>
                            <div>
                                <SkeletonTheme color='#ddd' highlightColor='#eee'>
                                    {loading ? (
                                        <div>
                                            <Skeleton className={UserClasses['prodskel']} />
                                        </div>
                                    ) : (
                                        <div style={{ overflowY: 'hidden' }}>
                                            <h3 style={{backgroundColor: 'black', padding: '10px', color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Variants</h3>
                                            <table className={ProductClasses['product-variants-table']}>
                                                <thead>
                                                    <tr className={UserClasses['main-table-heads']}>
                                                        <th>Metric Type & Value</th>
                                                        <th>Price</th>
                                                        <th>Stock</th>
                                                        <th>Created At</th>
                                                        <th>Updated At</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productVariants.length > 0 ? (
                                                        productVariants.map((variant) => (
                                                            <tr key={variant.variantId} data-aos="fade-up"
                                                                data-aos-anchor-placement="top-bottom">
                                                                <td>{variant.metricValue}{variant.metricType} </td>
                                                                <td>{variant.price}</td>
                                                                <td>{variant.stock}</td>
                                                                <td>{formatDate(variant.createdAt)}</td>
                                                                <td>{formatDate(variant.updatedAt)}</td>
                                                                <td>
                                                                    <button
                                                                        className={`${CategoryClasses['edit-button']} ${CategoryClasses['button']}`}
                                                                        onClick={() => openEditModal(variant)}
                                                                    >
                                                                        <FaRegEdit />
                                                                    </button>
                                                                    <button
                                                                        className={`${CategoryClasses['delete-button']} ${CategoryClasses['button']}`}
                                                                        onClick={() => handleDeleteVariant(variant.variantId)}
                                                                    >
                                                                        <AiFillDelete />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={5}>No Data Found</td>
                                                        </tr>
                                                    )}
                                                    {addingVariant && (
                                                        <tr data-aos="zoom-in">
                                                            <td className={ProductClasses['input-metric-val']} colSpan={1}>
                                                                <select
                                                                    value={metricType}
                                                                    onChange={(e) => setMetricType(e.target.value)}
                                                                >
                                                                    <option value="" disabled>Type</option>
                                                                    {metricValueOptions.map((option) => (
                                                                        <option key={option} value={option}>{option}</option>
                                                                    ))}
                                                                </select>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Metric Value"
                                                                    value={metricValue}
                                                                    onChange={(e) => setMetricValue(e.target.value)}
                                                                />
                                                            </td>
                                                            <td className={ProductClasses['metric-new-price']} colSpan={2}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="New Price"
                                                                    value={newPrice}
                                                                    onChange={(e) => setNewPrice(e.target.value)}
                                                                />
                                                            </td>
                                                            <td className={ProductClasses['metric-new-price']} colSpan={2}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Stock"
                                                                    value={newStock}
                                                                    onChange={(e) => setNewStock(e.target.value)}
                                                                />
                                                            </td>
                                                            <td className={UserClasses['add-users-btn']} colSpan={1}>
                                                                <Button variant="contained" style={{ backgroundColor: '#3498db', color: '#fff', margin: '5px', width: '100px', margin: '0 10px' }} onClick={handleConfirmAddVariant}>OK</Button>
                                                                <Button variant="contained" style={{ backgroundColor: '#C2CDDF', color: '#fff', margin: '5px', width: '100px', margin: '0 10px' }} onClick={handleCancelAddVariant}>Cancel</Button>
                                                                {error && <p style={{ color: 'red', margin: '5px' }}>{error}</p>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                </SkeletonTheme>
                            </div>

                        </div>
                    </div>
                    <div>
                        <SkeletonTheme color="#ddd" highlightColor="#eee">
                            {loading ? (
                                <div className={UserClasses['prod-main-skel']}>
                                    <Skeleton width={'300px'} height={'15rem'} count={1}/>
                                    <Skeleton  width={'650px'} height={'15rem'} count={1}/>
                                </div>
                            ) : (
                                <div style={{ overflowY: 'hidden' }}>
                                    {nutritionalValues ? (
                                        <div className={ProductClasses['image-and-nutrition-container']}>
                                            {nutritionalValues && nutritionalValues !== 'undefined' ? (
                                                <div style={{backgroundColor: '#EAFBE5'}} className={ProductClasses['nutri-val-main']} data-aos="fade-right">
                                                    <h2>{selectedProductName}</h2>
                                                    <img src={url} alt="Product Image" className={ProductClasses['product-image']} />
                                                </div>
                                            ) : (
                                                <div className={ProductClasses['no-image']}>
                                                    <p>No Image Available</p>
                                                </div>
                                            )}
                                            <div className={ProductClasses['nutritional-values-new']} data-aos="fade-left">
                                                <h3 className={ProductClasses['header-nutri-val']}>NUTRITIONAL VALUES</h3>
                                                <div className={ProductClasses['nutritional-values']} style={{ overflowY: 'scroll' }}>
                                                    <ul>
                                                        {nutritionalValues.map((item) => (
                                                            <li key={item.key}>
                                                                {item.key}: {item.value}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={ProductClasses['no-nutritional-data']}>
                                            <p>No Nutritional Data</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </SkeletonTheme>
                    </div>

                </div>
            </div>
            {/* Backdrop for edit modal */}
            {isEditModalOpen && (
                <div className={ProductClasses['backdrop']} onClick={closeEditModal}></div>
            )}
            {editingVariant && (
                <tr>
                    <td colSpan={5}>
                        <div className={ProductClasses["new-modal"]}>
                            <div className={ProductClasses["new-modal-content"]} data-aos="fade-right">
                                <h2>Edit Product Variant</h2>
                                {/* Input fields for editing */}
                                Metric Type & Value
                                <label>

                                    <select className={ProductClasses["ed-mod-one"]}
                                        value={editedMetricType}
                                        onChange={(e) => setEditedMetricType(e.target.value)}
                                    >
                                        <option value="" disabled>Select Metric Type</option>
                                        {['ml', 'ltr', 'gm', 'kg', 'units'].map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </select>
                                </label>
                                <label>
                                    <input className={ProductClasses["ed-mod-two"]}
                                        type="text"
                                        value={editedMetricValue}
                                        onChange={(e) => setEditedMetricValue(e.target.value)}
                                    />
                                </label>
                                <label>
                                    Price
                                    <input
                                        className={ProductClasses['last-inp']}
                                        type="text"
                                        value={editedPrice}
                                        onChange={(e) => setEditedPrice(e.target.value)}
                                    />
                                </label>
                                <label>
                                    Stock
                                    <input
                                        className={ProductClasses['last-inp']}
                                        type="text"
                                        value={editedStock}
                                        onChange={(e) => setEditedStock(e.target.value)}
                                    />
                                </label>
                                {/* Error message if any */}
                                {editError && <p className={ProductClasses["error-message"]}>{editError}</p>}
                                {/* Buttons for confirming/canceling the edit */}
                                <div className={ProductClasses['last-cancel-ok']}>
                                    <Button onClick={handleEditVariant}>OK</Button>
                                    <Button className={ProductClasses['cancel-mod']} onClick={closeEditModal}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )}

        </Layout>
    );
};

export default ProductDetails;